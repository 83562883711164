import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shorten'
})
export class ShortenPipe implements PipeTransform {

  transform(value: string, maxLength: number): string {
    if(value.length > maxLength){
      return value.substr(0, maxLength) + '...';
    }

    return value;
  }

}
