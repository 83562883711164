<header>
  <div class="notification" *ngIf="notification">
    <p>{{notification}}</p>
  </div>
  <nav class="navbar navbar-expand-lg navbar-light bg-light" id="nav">
    <!--    <img src="assets/favicon.ico" style="width: 50px">-->
    <a class="navbar-brand" routerLink="/">Online auction</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse"
            data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
            aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item" routerLinkActive="active">
          <a class="nav-link" routerLink="/product/latest">Home</a>
        </li>
        <li class="nav-item" routerLinkActive="active">
          <a class="nav-link" routerLink="/product/all">All products</a>
        </li>
        <li class="nav-item" routerLinkActive="active" *ngIf="!isLoggedIn">
          <a class="nav-link" routerLink="/user/login">Login</a>
        </li>
        <li class="nav-item" routerLinkActive="active" *ngIf="!isLoggedIn">
          <a class="nav-link" routerLink="/user/register">Register</a>
        </li>
        <li class="nav-item" routerLinkActive="active" *ngIf="isLoggedIn">
          <a class="nav-link" routerLink="/product/create">Create</a>
        </li>
        <li class="nav-item" routerLinkActive="active" *ngIf="isLoggedIn">
          <a class="nav-link" routerLink="/user/profile">Profile</a>
        </li>
        <li class="nav-item" *ngIf="isLoggedIn" (click)="logoutHandler()">
          <a class="nav-link">Logout</a>
        </li>
      </ul>
      <span class="navbar-text" *ngIf="isLoggedIn">
        Welcome, {{email}}
      </span>
      <div class="change-theme" style="margin-left: 20px">
        Dark mode
        <mat-slide-toggle color="primary" appChangeTheme #matSlideToggle='appChangeTheme'
                          [checked]="matSlideToggle.isToggleChecked"></mat-slide-toggle>
      </div>
    </div>
  </nav>
  <h1>online gem auction</h1>

</header>
